/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { finishEmailAuth as FinishEmailAuth } from '../services/firebase';

const FinishSignInContainer = ({ onLogin }) => {
  const history = useHistory();

  useEffect(() => {
    const finishSignIn = async () => {
      const location = window.location.href;
      const response = await FinishEmailAuth(location);
      if (response.success) {
        onLogin(response.data);
        history.push('/');
      } else {
        history.push('/');
      }
    };
    finishSignIn();
  }, []);

  return <div className="App-content"></div>;
};

export default FinishSignInContainer;
