import React, { useState } from 'react';
import { startEmailAuth as StartEmailAuth } from '../services/firebase';

const EmailVerificationLink = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await StartEmailAuth(email);
    if (response.success) {
      window.localStorage.setItem('pair-auth-emailsignin', email);
      alert('Please check you email to continue.');
    } else {
      alert('Error. Check console');
      console.log('ERROR', response.error);
    }
  };
  
  return (
    <div className="email-verification-form">
      <h2>- OR -</h2>
      <form onSubmit={handleSubmit}>
        <label>Email Address</label>
        <input
          type="email"
          placeholder="yourname@gmail.com"
          className="email-input"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="email-btn">
          Log in
        </button>
      </form>
    </div>
  );
};
export default EmailVerificationLink;
