import React, { useState } from 'react';

import GoogleAuthButton from '../components/google-auth-button';
import AppleAuthButton from '../components/apple-auth-button';
import EmailVerificationForm from '../components/email-verification-form';
import { googleAuth as GoogleAuth, appleAuth as AppleAuth } from '../services/firebase';

import logo from '../assets/images/logo.svg';
import '../assets/styles/App.css';

const LoginContainer = (props) => {
  const { onLogin } = props;
  const [, setUser] = useState(null);

  const onGoogleLogin = async () => {
    const response = await GoogleAuth();
    if (response.success) {
      setUser(response.data);
      onLogin(response.data);
    } else {
      console.log('GOOGLE ERROR', response.error);
    }
  };

  const onAppleLogin = async () => {
    const response = await AppleAuth();
    if (response.success) {
      setUser(response.data);
      onLogin(response.data);
    } else {
      console.log('APPLE ERROR', response.error);
    }    
  };

  return (
    <div className="App-content">
      <div className="App-body">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" /><br/>
          <h1>Log in</h1>
        </div>
        <div className="App-auth">
          <AppleAuthButton handleClick={onAppleLogin} />
          <GoogleAuthButton handleClick={onGoogleLogin} />
          <EmailVerificationForm />
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
