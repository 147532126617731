import axios from 'axios';

const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;

export const checkCode = (code, userId) => {
  const headers = {
    "x-firebase-user-id": userId,
  }
  return axios.get(`${REACT_APP_API_URL}/code?code=${code}&apikey=${REACT_APP_API_KEY}`, { headers });
}
