import React from 'react';

const Layout = (props) => (
  <div className="App">
    <section>
      <div className="App-main">
        {props.children}
        <div className="App-note">* Illustration made by vectorjuice</div>
      </div>
    </section>
  </div>
);

export default Layout;
