import React from 'react';

const Loading = (props) => (
  <div className="App-content">
    <div className="App-body">
      <div className="App-main">
        <h1>Loading...</h1>
      </div>
    </div>
  </div>
);

export default Loading;
