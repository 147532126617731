import firebase from 'firebase/app';
import 'firebase/auth';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_REDIRECT_URL,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
};

const actionCodeSettings = {
  url: REACT_APP_REDIRECT_URL,
  handleCodeInApp: true,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const performAuth = async (provider) => {
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    const { idToken: token } = result.credential;
    const user = result.user;

    return { success: true, data: { token, user } };
  } catch (error) {
    return { success: false, error };
  }
};

export const googleAuth = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return performAuth(provider);
};

export const appleAuth = async () => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  return performAuth(provider);
};

export const startEmailAuth = async (email) => {
  try {
    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
    return { success: true, email };
  } catch (error) {
    return { success: false, error };
  }
};

export const finishEmailAuth = async (location) => {
  const authExists = firebase.auth().isSignInWithEmailLink(window.location.href);
  if (authExists) {
    let email = window.localStorage.getItem('pair-auth-emailsignin');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }
    try {
      const response = await firebase.auth().signInWithEmailLink(email, window.location.href);
      console.log('RESPONSE', response);
      const { user } = response;
      window.localStorage.getItem('pair-auth-emailsignin');
      return { success: true, data: { token: user.refreshToken, user } };
    } catch (error) {
      console.log('ERROR', error);
      window.localStorage.getItem('pair-auth-emailsignin');
      return { success: false, error };
    }
  }
  return { success: false };
};

export default firebase;
