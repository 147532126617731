import React, { useState, useRef } from 'react';
import PinInput from 'react-pin-input';
import { checkCode } from '../api';
import logo from '../assets/images/logo.svg';
import '../assets/styles/App.css';

const CodeContainer = ({ user }) => {
  const [code, setCode] = useState('');
  const [response, setResponse] = useState('');
  const pinInputRef = useRef(null);

  const onComplete = (value) => {
    setCode(value);
  };

  const onContinue = async () => {
    try {
      const { data } = await checkCode(code, user.userId);
      if (data.id) {
        setResponse('Success');
      } else {
        setResponse('Failed');
      }
    } catch (e) {
      setResponse('Network Error. Check console');
      console.log(e);
    }
  };
  return (
    <div className="App-content">
      <div className="App-body">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <h1>Linking Quran Pro with your account</h1>
        <p>Enter the code you see on your TV</p>
        <div className="code-input">
          <PinInput length={5} focus ref={pinInputRef} type="custom" onComplete={onComplete} />
          <button type="button" className="continue-btn" onClick={onContinue}>
            Continue
          </button>
        </div>
        {response && <div className="response">{response}</div>}
      </div>
    </div>
  );
};

export default CodeContainer;
