import React from 'react';
import illustration from '../assets/images/illustration.svg';
import logo from '../assets/images/logo-alt.svg';

const Illustration = () => (
  <div className="App-illustration">
    <img src={illustration} className="illustration" alt="illustration" />
    <img src={logo} className="illustration-logo" alt="logo" />
  </div>
);

export default Illustration;
