import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Layout from './components/layout';
import Loading from './components/loading';
import CodeContainer from './containers/code';
import LoginContainer from './containers/login';
import Illustration from './components/illustration';
import './assets/styles/App.css';
import FinishSignInContainer from './containers/finish-sign-in';

const getCurrentTime = () => new Date().getTime();

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const onLogin = (response) => {
    const { token, user } = response;
    if (token && user.uid) {
      console.log('SETY USER', { token: token, userId: user.uid })
      setUser({ token: token, userId: user.uid });
      window.localStorage.setItem('pair-auth-token', token);
      window.localStorage.setItem('pair-auth-userid', user.uid);
      window.localStorage.setItem('pair-auth-logintime', getCurrentTime());
    }
  };

  const renderContent = () => {
    if (loading) return <Loading />;
    if (user) return <CodeContainer user={user} />;
    return <LoginContainer onLogin={onLogin} />;
  };

  useEffect(() => {
    const checkToken = async () => {
      const token = window.localStorage.getItem('pair-auth-token');
      const userId = window.localStorage.getItem('pair-auth-userid');
      const loginTime = window.localStorage.getItem('pair-auth-logintime');
      const currentTime = getCurrentTime();

      const authDays = (currentTime - loginTime) / 1000 / 3600 / 24; // days from login
      const resetAuth = !token || !userId || !loginTime || authDays > 7; // reset if no data in storage or more than 7 days passed after last login
      if (resetAuth) {
        window.localStorage.removeItem('pair-auth-token');
        window.localStorage.removeItem('pair-auth-userid');
        window.localStorage.removeItem('pair-auth-logintime');
      }
      if (token && userId) {
        setUser({ token, userId });
      }
      setLoading(false);
    };
    checkToken();
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/finish-sign-in">
          <FinishSignInContainer onLogin={onLogin} />
        </Route>
        <Route path="/">
          <Layout>
            {renderContent()}
            <Illustration />
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
